import React, { useEffect, useRef } from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatMessage from "./ChatMessage2024";
import { chatMessageStateObject } from "./ChatInterface";

interface ChatContainerProps {
  chatFontSize: number;
  chatBackgroundColor: string;
  chatTextColor: string;
  videoPaneCols: number;
  showAutoTexts: boolean;
  chatMessages: chatMessageStateObject[];
  chatOptions: {
    autotext1: string;
    autotext2: string;
    autotext3: string;
    autotext4: string;
    autotext5: string;
  };
  chatTargetPseudo: string;
  canWrite: boolean;
  currentChatMessage: string;
  chatMessageContainerRef: React.RefObject<HTMLDivElement> | null;
  chatInput: React.RefObject<HTMLInputElement> | null;
  selectUser: (pseudo: string, guid: string) => void;
  videoMoveRight: () => void;
  chatFontPlus: () => void;
  chatFontMinus: () => void;
  getColorFromClassName: (className: string) => string;
  getText: (key: string) => string;
  sendChatText: () => void;
  handleChatMessageKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChatMessageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChatContainer2024: React.FC<ChatContainerProps> = (props) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.chatMessages]);

  const sanitizeInput = (input: string) => {
    return input.replace(/\bto\b/g, "").trim();
  };

  return (
    <div
      id="GuppyChatMessageContainer"
      style={{
        fontSize: props.chatFontSize,
        backgroundColor: props.chatBackgroundColor,
        color: props.chatTextColor,
        position: "relative",
        height: "100%",
        overflow: "hidden",
      }}
      ref={props.chatMessageContainerRef}
    >
      <div id="chatMessageButtons">
        {props.videoPaneCols === 0 ? (
          <Button
            id="btnVideoMoveRight2"
            onClick={props.videoMoveRight}
            className="mr-1"
            variant="secondary"
            size="sm"
          >
            <FontAwesomeIcon icon={["fas", "arrow-square-right"]} />
          </Button>
        ) : null}
        <Button
          id="btnFontPlus"
          onClick={props.chatFontPlus}
          variant="secondary"
          size="sm"
        >
          <FontAwesomeIcon icon={["fas", "text-height"]} />
        </Button>
        <Button
          id="btnFontMinus"
          onClick={props.chatFontMinus}
          className="ml-1"
          variant="secondary"
          size="sm"
        >
          <FontAwesomeIcon icon={["fas", "text"]} />
        </Button>
      </div>
      {props.showAutoTexts && (
        <div id="autoTextContainer">
          #1: {props.chatOptions.autotext1}
          <br />
          #2: {props.chatOptions.autotext2}
          <br />
          #3: {props.chatOptions.autotext3}
          <br />
          #4: {props.chatOptions.autotext4}
          <br />
          #5: {props.chatOptions.autotext5}
        </div>
      )}
      <div
        id="chatMessages"
        style={{
          paddingBottom: "60px",
          height: "calc(100% - 60px)",
          overflowY: "auto",
          paddingTop: "15px",
        }}
      >
        {props.chatMessages.map((msg, index) => (
          <div
            key={msg.id}
            style={{
              cursor: "pointer",
              color: props.getColorFromClassName(msg.className),
            }}
            onClick={() => {
              if (
                msg.fromPart &&
                msg.fromPart.trim() !== "" &&
                !msg.fromPart.toLowerCase().includes(" an ") &&
                !msg.fromPart.toLowerCase().includes(" to ")
              ) {
                props.selectUser(msg.fromPart, msg.id.toString());
              }
            }}


          >
            <ChatMessage
              className={msg.className}
              color={props.getColorFromClassName(msg.className)}
              msg={msg.msg}
              fromPart={msg.fromPart}
              messageClass={msg.messageClass}
              messageColor={props.getColorFromClassName(msg.messageClass)}
            />
          </div>
        ))}
        {/* Invisible div for auto-scrolling */}
        <div ref={messagesEndRef} />
      </div>
      <div
        id="chatInputContainer"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <InputGroup
          className="mr-2 ml-2"
          style={{
            flex: 1,
            borderRadius: "20px",
            padding: "5px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
            <InputGroup.Text
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 12px",
                  borderRadius: "20px 0 0 20px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#495057",
                  background: props.chatTargetPseudo === "" ? "transparent" : "rgba(236, 234, 85, 0.7)",
                  border: "1px solid #dee2e6",
                  borderRight: "none",
                  cursor: "pointer", // 🖱 Macht sichtbar, dass es klickbar ist
                  transition: "background 0.3s ease-in-out", // Weicher Übergang für modernes UI
                }}
              onClick={() => props.selectUser("", "")} // 💡 Setzt Chat auf "ToAll"
            >
              {props.chatTargetPseudo === ""
                ? props.getText("ToAll")
                : props.getText("To") + " " + props.chatTargetPseudo}
            </InputGroup.Text>
          <FormControl
            ref={props.chatInput}
            disabled={!props.canWrite}
            value={props.currentChatMessage}
            onKeyDown={props.handleChatMessageKeyDown}
            onChange={props.handleChatMessageChange}
            placeholder={props.getText("Chatmessage")}
            style={{
              border: "none",
              outline: "none",
              boxShadow: "none",
              fontSize: "16px",
              color: "#333",
              padding: "10px",
            }}
          />
            <Button
              variant="primary"
              disabled={!props.canWrite}
              onClick={props.sendChatText}
              style={{
                borderRadius: "20px",
                fontWeight: "bold",
                padding: "6px 16px",
                fontSize: "14px",
              }}
            >
              Send
            </Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default ChatContainer2024;
