import React, { Component, useEffect, useRef, RefObject } from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatMessage from "./ChatMessage"; // Importiere deine ChatMessage-Komponente
import { chatMessageStateObject } from "./ChatInterface"; // Stelle sicher, dass der Pfad korrekt ist

// Definiere die Typen der Props
interface ChatContainerProps {
  chatFontSize: number;
  chatBackgroundColor: string;
  chatTextColor: string;
  videoPaneCols: number;
  showAutoTexts: boolean;
  chatMessages: chatMessageStateObject[];
  chatOptions: {
    autotext1: string;
    autotext2: string;
    autotext3: string;
    autotext4: string;
    autotext5: string;
  };
  chatTargetPseudo: string;
  canWrite: boolean;
  currentChatMessage: string;
  chatMessageContainerRef: RefObject<HTMLDivElement> | null;
  chatInput: RefObject<HTMLInputElement> | null;
  videoMoveRight: () => void;
  chatFontPlus: () => void;
  chatFontMinus: () => void;
  getColorFromClassName: (className: string) => string;
  getText: (key: string) => string;
  sendChatText: () => void;
  handleChatMessageKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChatMessageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChatContainer: React.FC<ChatContainerProps> = (props) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Automatisch nach unten scrollen
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Bei jeder Änderung der chatMessages nach unten scrollen
  useEffect(() => {
    scrollToBottom();
  }, [props.chatMessages]);
  return (
    <div
      id="chatMessageContainer"
      style={{
        fontSize: props.chatFontSize,
        backgroundColor: props.chatBackgroundColor,
        color: props.chatTextColor,
        position: "relative", // Relativ für absolute Positionierung der Kinder
        height: "100%", // Nutze die gesamte Höhe des Viewports
        overflow: "hidden", // Verhindert, dass Inhalte überlaufen
      }}
      ref={props.chatMessageContainerRef}
    >
      <div id="chatMessageButtons">
        {props.videoPaneCols === 0 ? (
          <Button
            id="btnVideoMoveRight2"
            onClick={props.videoMoveRight}
            className="mr-1"
            variant="secondary"
            size="sm"
          >
            <FontAwesomeIcon icon={["fas", "arrow-square-right"]} />
          </Button>
        ) : null}
        <Button
          id="btnFontPlus"
          onClick={props.chatFontPlus}
          variant="secondary"
          size="sm"
        >
          <FontAwesomeIcon icon={["fas", "text-height"]} />
        </Button>
        <Button
          id="btnFontMinus"
          onClick={props.chatFontMinus}
          className="ml-1"
          variant="secondary"
          size="sm"
        >
          <FontAwesomeIcon icon={["fas", "text"]} />
        </Button>
      </div>
      {props.showAutoTexts ? (
        <div id="autoTextContainer">
          #1: {props.chatOptions.autotext1}
          <br />
          #2: {props.chatOptions.autotext2}
          <br />
          #3: {props.chatOptions.autotext3}
          <br />
          #4: {props.chatOptions.autotext4}
          <br />
          #5: {props.chatOptions.autotext5}
        </div>
      ) : null}
       <div
        id="chatMessages"
        style={{
            paddingBottom: "60px", // Platz für den Eingabebereich schaffen
            height: "calc(100% - 60px)", // Höhe anpassen, um den Eingabebereich auszuschließen
            overflowY: "auto", // Ermöglicht Scrollen bei vielen Nachrichten
          }}
      > {props.chatMessages.map((msg, index) => (
        <ChatMessage
          key={msg.id}
          className={msg.className}
          color={props.getColorFromClassName(msg.className)}
          msg={msg.msg}
          fromPart={msg.fromPart}
          messageClass={msg.messageClass}
          messageColor={props.getColorFromClassName(msg.messageClass)}
        />
      ))}<div ref={messagesEndRef}></div></div>
     
      <div id="chatInputContainer" className="ml-2 mr-2" style={{
          display: "flex",
          alignItems: "center",
          padding: "12px",
          width: "100%"
        }}>
        <InputGroup className="mr-2 ml-2">
            <InputGroup.Text>
              {props.chatTargetPseudo === ""
                ? props.getText("ToAll")
                : props.getText("To") + " " + props.chatTargetPseudo}
            </InputGroup.Text>
          <FormControl
            ref={props.chatInput}
            disabled={!props.canWrite}
            value={props.currentChatMessage}
            onKeyDown={props.handleChatMessageKeyDown}
            onChange={props.handleChatMessageChange}
            placeholder={props.getText("Chatmessage")}
          />
            <Button
              variant="secondary"
              disabled={!props.canWrite}
              onClick={props.sendChatText}
            >
              Send
            </Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default ChatContainer;
