import { useEffect, useState, useContext, forwardRef, useImperativeHandle } from "react";
import AppContext from "./AppContext";
import { CamExtension, CamToyStatus } from "./lovenseCamExtTypes";

interface LovenseChromeExtensionProps {
  onLovenseStatusChange: (enabled: boolean) => void;
  socket: SocketIOClient.Socket | null;
}

const LovenseChromeExtension = forwardRef(({ onLovenseStatusChange, socket }: LovenseChromeExtensionProps, ref) => {
  const context = useContext(AppContext);
  const [camExtension, setCamExtension] = useState<CamExtension | null>(null);
  const [settings, setSettings] = useState<any>(null); // Hier speichern wir die Lovense-Einstellungen

  useEffect(() => {
    /*if (!context?.loginData?.pseudo.toLowerCase().includes("testsender")) {
      return;
    }*/

    if (!window.CamExtension) {
      console.warn("CamExtension is not available in the window object.");
      return;
    }

    const cam = new window.CamExtension("Livestrip", context.loginData?.pseudo ?? "");
    setCamExtension(cam);
    console.log("CamExtension initialized");

    const handleReady = async (instance: CamExtension) => {
      console.log("CamLogin Wert:", instance.camLogin);

      try {
        const settings = await instance.getSettings();
        console.log("Automatically fetched Lovense Settings:", settings);
        setSettings(settings);
        console.log("CamExtension is ready:", instance);
      } catch (error) {
        console.error("Failed to fetch Lovense settings:", error);
      }
    };

    const handleToyStatusChange = (data: CamToyStatus[]) => {
      data.forEach((toy) => {
        console.log("Status:", toy.status);
        onLovenseStatusChange(toy.status === "on");
      });
    };

    const handlePostMessage = (message: string) => {
      if (socket) {
        socket.emit("message", { msg: message, to: "" });
        console.log("Post Message from camExtension.on:", message);
      } else {
        console.warn("Socket is not available.");
      }
    };
    cam.on("ready", handleReady);
    cam.on("toyStatusChange", handleToyStatusChange);
    cam.on("postMessage", handlePostMessage); // ✅ Post Message Event hinzufügen

    return () => {
      cam.off("ready", handleReady);
      cam.off("toyStatusChange", handleToyStatusChange);
      cam.off("postMessage", handlePostMessage); // ✅ Event sauber entfernen
    };
  }, [context?.loginData?.pseudo, onLovenseStatusChange, socket]);

  useImperativeHandle(ref, () => ({
    sendTip: (coins: number, userPseudo: string) => {
      if (camExtension) {
        console.log(`Sending ${coins} coins from ${userPseudo} to CamExtension.`);
        camExtension.receiveTip(coins, userPseudo);
      } else {
        console.warn("CamExtension is not initialized.");
      }
    },
    receiveMessage: (message: string, targetPseudo: string) => {
      if (camExtension) {
        console.log(`Sending message "${message}" to ${targetPseudo}.`);
        camExtension.receiveMessage(message, targetPseudo);
      } else {
        console.warn("CamExtension is not initialized.");
      }
    },
    getSettings: async () => {
      if (camExtension) {
        try {
          const settings = await camExtension.getSettings();
          console.log("Lovense Settings:", settings);
          setSettings(settings); // Speichert die Einstellungen im State
          return settings;
        } catch (error) {
          console.error("Failed to retrieve Lovense settings:", error);
          return null;
        }
      } else {
        console.warn("CamExtension is not initialized.");
        return null;
      }
    }
  }), [camExtension]); // ✅ `camExtension` als Abhängigkeit gesetzt

  return null;
});

export default LovenseChromeExtension;
