import React from "react";
import AppContext from "./AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faUser, faCog } from "@fortawesome/free-solid-svg-icons";

class ChatMessage extends React.Component {
  static contextType = AppContext;

  render() {
    const isWhisper =
      (this.props.fromPart.toLowerCase().includes(" to ") || this.props.fromPart.toLowerCase().includes(" an ")) &&
      !this.props.fromPart.toLowerCase().includes("to all") &&
      !this.props.fromPart.toLowerCase().includes("an alle");

    if (this.props.fromPart === "") {
      return (
        <div
          className={this.props.className}
          style={{ color: this.props.color, padding: "0 15px" }}
        >
          {this.props.className.includes("chat-sysmessage") && (
            <FontAwesomeIcon icon={faCog} style={{ marginRight: "5px", color: this.props.messageColor }} />
          )}
            {this.props.className.includes("chat-leave") && (
            <FontAwesomeIcon icon={faUser} style={{ marginRight: "5px", color: this.props.messageColor }} />
          )}
           {this.props.className.includes("chat-enter") && (
            <FontAwesomeIcon icon={faUser} style={{ marginRight: "5px", color: this.props.messageColor }} />
          )}
          {this.props.className.includes("chat-sysmessage") ? (
            <span>
              <i>{this.props.msg}</i>
            </span>
          ) : (
            <span>{this.props.msg}</span>
          )}
        </div>
      );
    } else {
      const isGirl = this.props.messageClass === "chat-from-girl";

      return (
        <div style={{
          display: "flex",
          justifyContent: isGirl ? "flex-end" : "flex-start",
          padding: "0 15px",
        }}>
          <div
            className={`chat-message ${isWhisper ? "whisper-bubble" : (isGirl ? "girl-bubble" : "user-bubble")}`}
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div style={{
              fontWeight: "bold",
              marginBottom: "3px",
              color: this.props.messageColor,
            }}>
                      {this.props.messageClass === "chat-from-girl" && (
                        <FontAwesomeIcon icon={faVideo} style={{ marginRight: "5px", color: "rgb(255, 218, 185)" }} />
                    )}
                    {this.props.messageClass.includes("chat-from-user") && (
                        <FontAwesomeIcon icon={faUser} style={{ marginRight: "5px", color: "rgb(255, 218, 185)" }} />
                    )}
              {this.props.fromPart}:
            </div>
            <div>{this.props.msg}</div>
          </div>
        </div>
      );
    }
  }
}

export default ChatMessage;
